import React from "react"

import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => {
  
  return (
    <>
      <SEO title="Home" />
      <h1>Welcome to (ISC)² Sacramento Chapter Administration! </h1>
      <p>This site is used for the chapters administrative tasks.</p>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Image />
      </div>
    </>
  )
}

export default IndexPage
